import React from "react"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import { graphql } from "gatsby"
import Recommendations from "../components/recommendations";

export default class Article extends React.Component {
    render() {
        let global = this.props.data.contentfulWebsite;
        return (
            <Layout pathname={this.props.location.pathname}>
                <Seo metaTitle={`${this.props.pageContext.title} - ${global.title}`} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
                <div className="o-wrapper o-content o-container">
                    <div className="o-content-spacer o-content-container o-content-container--large">
                        <img className="u-full-width" src={this.props.pageContext.banner_img.resize.src} alt={this.props.pageContext.title} />
                        <div className="o-flex o-flex--third o-flex--align-start">
                            <div className="u-hide u-hide--mobile">
                                <img src={this.props.pageContext.side_img.resize.src} alt={this.props.pageContext.title} />
                            </div>
                            <div className="o-content-container o-flex__col-2">
                                <h2>
                                    {this.props.pageContext.title}<br />
                                    <span className="u-unbold">{this.props.pageContext.subtitle}</span>
                                </h2>
                                <div>{this.props.pageContext.description.description}</div>
                                <a className="c-button" href={`/series/${this.props.pageContext.slug}/episodes`}>
                                    {global.videoCtaText}<br />
                                    <span className="u-unbold">{this.props.pageContext.cta_tagline}</span>
                                </a>
                            </div>
                        </div>
                        <Recommendations recommendations={this.props.pageContext.recommendations} videoCtaText={global.videoCtaText}></Recommendations>
                    </div>
                </div>
            </Layout>
        );
    };
}

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "UNINTERRUPTED Canada"}) {
        author
        description
        title
        videoCtaText
        shareImage {
            file {
                url
            }
        }
    }
}
`;
